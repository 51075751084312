<template>
  <div>
    <div class="flex content-between flex-wrap BgWhiteBS mr-2 h-full p-4">
      <div class="pl-4 mt-1 w-full">
        <div class="flex" style="color: #434343; font-size: 1.2rem">
          <span @click="OpenCustomersPage" style="cursor: pointer"
            >Customers</span
          >
          <span class="ml-2 mr-2"> > </span>
          <span @click="OpenCustomerDetailPage" style="cursor: pointer">
            {{ companyName }}
          </span>
          <span class="ml-2 mr-2"> > </span>
          <span style="font-weight: 600">Orders</span>
        </div>
      </div>
    </div>
    <div class="BgWhiteBS p-3 mt-2">
      <div class="vx-row filterBy w-full">Filter By</div>
      <div class="vx-row w-full">
        <div class="vx-row titles">
          <div class="vx-row items-center mr-4 mt-6">Purchase Date</div>
          <div class="vx-row items-center mr-4">
            <div class="vx-col text-center">
              <div>
                Start <vs-icon icon="date_range" color="dark"> </vs-icon>
              </div>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="fromDate"
                placeholder="dd.mm.yyyy"
                @on-change="onFromChange"
              />
            </div>

            <div class="mt-6">-</div>

            <div class="vx-col text-center">
              <div>End <vs-icon icon="date_range" color="dark"> </vs-icon></div>
              <flat-pickr
                :config="configTodateTimePicker"
                v-model="toDate"
                placeholder="dd.mm.yyyy"
                @on-change="onToChange"
              />
            </div>
          </div>
          <vs-button
            class="mt-6"
            type="filled"
            icon="filter_alt"
            @click="GetOrders()"
          ></vs-button>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>
        <div class="vx-row titles mt-8">
          <div class="vx-col p-0 m mx-auto mr-2">
            <vs-radio
              v-model="selectedStatus"
              vs-value=""
              color="rgb(87, 251, 187)"
              >All</vs-radio
            >
          </div>
          <div class="vx-col p-0 m mx-auto mr-2">
            <vs-radio v-model="selectedStatus" vs-value="N">New</vs-radio>
          </div>
          <div class="vx-col p-0 m mx-auto mr-2">
            <vs-radio v-model="selectedStatus" vs-value="P" color="warning"
              >Processing</vs-radio
            >
          </div>
          <div class="vx-col p-0 m mx-auto mr-2">
            <vs-radio v-model="selectedStatus" vs-value="W" color="#389CA3"
              >Waiting Payment</vs-radio
            >
          </div>
          <div class="vx-col p-0 m mx-auto mr-2">
            <vs-radio v-model="selectedStatus" vs-value="R" color="success"
              >Ready</vs-radio
            >
          </div>
          <div class="vx-col p-0 m mx-auto">
            <vs-radio v-model="selectedStatus" vs-value="C" color="danger"
              >Cancelled</vs-radio
            >
          </div>
        </div>
      </div>
    </div>

    <div class="BgWhiteBS vx-row p-1 m-0 mt-4 mb-4 orderBar pt-3 pb-3">
      <div class="vx-col md:w-1/12 text-center">No</div>
      <div class="vx-col md:w-1/12 text-center">Created</div>

      <div class="vx-col md:w-2/12 text-center">Total Resin / Credit</div>
      <div class="vx-col md:w-1/12 ml-auto text-center">Status</div>
    </div>

    <div class="BgWhiteBS" v-for="order in orders" :key="order.id">
      <order-row :order="order" @OpenOrderDetail="$emit('OpenOrderDetail')" />
    </div>
    <div class="m-2 my-8">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="HandleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import orderRow from "./components/OrderListRow.vue";

export default {
  data() {
    return {
      selectedStatus: "",
      companyName: "",
      totalPage: 1,
      currentPage: 1,
      orders: [],

      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
    };
  },
  methods: {
    //Date Functions
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    HandleChangePage(page) {
      this.currentPage = page;
      this.GetOrders();
    },
    GetOrders() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      let params = {};

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      if (this.fromDate) {
        params.created_after = this.fromDate;
      }

      if (this.toDate) {
        params.created_before = this.toDate;
      }

      if (this.selectedStatus) {
        params.order_status = this.selectedStatus;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_CUSTOMER +
          this.$route.params.id +
          "/orders/",
        this.HangleGetOrders,
        params
      );
    },
    HangleGetOrders(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.companyName = data.company_name;
        this.orders = data.results;
      }
    },
    OpenCustomersPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    OpenCustomerDetailPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/" + this.$route.params.id + "/",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
  },

  watch: {
    selectedStatus() {
      this.GetOrders();
    },
  },

  components: {
    orderRow,
    flatPickr,
  },
  created() {
    if (this.$route.query.order_status) {
      this.selectedStatus = this.$route.query.order_status;
    }

    if (this.$route.query.created_after) {
      this.fromDate = this.$route.query.created_after;
    }

    if (this.$route.query.created_before) {
      this.toDate = this.$route.query.created_before;
    }
  },
};
</script>

<style>
.flatpickr-input {
  max-width: 100px;
}
</style>

<style scoped>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.vx-row {
  padding: 0px;
  margin: 0px;
}
.grayLine {
  border: 1px solid #dedede;
}
.filterBy {
  color: #434343;
  font-weight: 900;
}
.titles {
  font-weight: 500;
  color: #434343;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
</style>
